<template>
    <v-card class="v-card--material mx-auto" color="white">
        <div class="d-flex--v2 grow flex-wrap">
            <v-sheet
                color="primary lighten-1"
                max-height="90"
                width="auto"
                class="text-start v-card--material__heading mb-n6 elevation-6 pa-7"
            >
                <v-icon large color="white">
                    mdi-newspaper
                </v-icon>
            </v-sheet>
            <div class="ml-6">
                <v-card-title>
                    <span
                        class="text-h6 font-weight-light"
                        v-text="$t('dashboard.leaNews.headline')"
                    />
                </v-card-title>
            </div>
        </div>
        <v-card-text class="pa-0">
            <template>
                <v-data-iterator
                    :items="items"
                    :options="options"
                    :page.sync="page"
                    :server-items-length="totalItems"
                    :footer-props="footerProps"
                    :loading="$wait.is('loading lea news')"
                    item-key="id"
                    class="feedIterator"
                >
                <template v-slot:loading>
                    <template
                        v-for="skeleton in footerProps.itemsPerPageOptions[0]"
                    >
                        <v-skeleton-loader
                            :key="skeleton"
                            :type="'article'"
                            tile
                            class="mx-auto"
                            min-height="206"
                        ></v-skeleton-loader>
                    </template>
                </template>
                    <template v-slot:item="props">
                        <v-sheet min-height="206">
                            <NewsItem
                                :i="props.index"
                                :items-length="items.length"
                                :item="props.item"
                            />
                        </v-sheet>
                    </template>
                    <template v-slot:pageText="props" color="success">
                        {{ props.pageStart }} - {{ props.pageStop }}
                        {{ $t('from') }} {{ props.itemsLength }}
                    </template>
                </v-data-iterator>
            </template>
        </v-card-text>
    </v-card>
</template>


<script>
import { mapActions } from 'vuex';
import NewsItem from './NewsItem.vue';
const LIMIT = 1;

export default {
    name: 'DashboardLeaNews',
    components: {
        NewsItem,
    },
    i18n: {
        messages: {
            en: require('@/locales/Dashboard/en.json'),
            de: require('@/locales/Dashboard/de.json'),
        },
    },
    data() {
        return {
            maxHeight: '100%',
            totalItems: 0,
            limit: LIMIT,
            items: [],
            page: 1,
            options: { sortDesc: true, itemsPerPage: LIMIT },
            onCreateLoader: false,
            footerProps: {
                itemsPerPageOptions: [LIMIT],
                itemsPerPageText: this.$t('perPage'),
                pageText: '{0}-{1} ' + this.$t('from') + ' {2}'
            },
        };
    },
    methods: {
        ...mapActions({
            fetchFilterItems: 'community/fetchFilterItems',
        }),
        fetchByPage(page) {
            this.$wait.start('loading lea news');
            this.items = [];
            this.fetchFilterItems({
                page: page,
                limit: this.limit,
                searchCategory: 'lea_news',
                status: 'published'
            }).then(data => {
                this.items = data.result;
                this.totalItems = data.total;
                this.$wait.end('loading lea news');
            });
        },
    },
    created() {
        this.$wait.end('loading lea news');
        this.fetchByPage(1);
    },
    watch: {
        page: {
            handler(page) {
                if(this.$route.name === 'Home') {
                    this.fetchByPage(page);
                }
            },
            deep: true,
        }
    },
};
</script>

<style lang="scss">
.v-data-iterator.feedIterator {
    .v-data-iterator__actions {
        margin-top: 50px;
    }
}
</style>
